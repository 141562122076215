import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import "./condiciones-legales.scss"
import {sendGoogleAdsEvent} from '../utils/analyticsService';

export default function FormularioContactoEnviado() {

	// Google Ads Conversion ==> visit contacto form
	sendGoogleAdsEvent();

  return (
    <React.Fragment>
      <SEO title="Formulario Contacto Enviado" />
      <Layout pageId="privacy-policy">
        <div className="privacy-policy-container">
          <h1>Hemos recibido su formulario de contacto</h1>
          <h4>En breve tendrá una respuesta</h4>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </Layout>
    </React.Fragment>
  )
}
